<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <div>
        <h1 class="mr-sm-4 header-tablepage">Import Adjust Point</h1>
      </div>
      <div class="bg-white border-red p-2 p-lg-3 mt-3">
        <b-row class="no-gutters mt-3">
          <b-col md="12">
            <UploadFileV2
              textFloat="File"
              placeholder="Please Choose File"
              format="excel"
              name="file"
              text="*Please upload only file .xlsx less than 10 MB"
              isRequired
              v-on:onFileChange="onFileChange"
              v-on:delete="deleteFile"
              :fileName="filename"
              v-model="form.excel_file"
              :v="$v.form.excel_file"
              id="uploadfile"
            />
          </b-col>
          <b-col cols="12">
            <!-- <label class="main-label"></label> -->
            <InputTextArea
              textFloat="Additional Reasons (if any)"
              type="text"
              v-model="form.note"
              class=""
              labelClass="text-gray"
              placeholder="Provide Additional Information"
              rows="3"
              name="message"
          /></b-col>
        </b-row>

        <b-row>
          <b-col>
            <router-link to="/report/customer">
              <b-button size="md" class="btn-cancel">Cancel</b-button>
            </router-link>
          </b-col>
          <b-col class="text-right">
            <b-button
              id="upload"
              class="main-color btn-save color-btn"
              size="md"
              :disabled="isDisable"
              @click="importData"
              >Upload</b-button
            >
          </b-col>
        </b-row>
      </div>
      <ModalInputEmail
        ref="ModalInputEmail"
        :form="form"
        @changeEmail="(val) => (form.email = val)"
        @submit="sendForm"
      />
    </div>
  </div>
</template>

<script>
import UploadFileV2 from "@/components/inputs/UploadFileV2";
import ModalInputEmail from "@/components/customer/ModalInputEmail";
import OtherLoading from "@/components/loading/OtherLoading";
import { required } from "vuelidate/lib/validators";
export default {
  components: {
    UploadFileV2,
    ModalInputEmail,
    OtherLoading,
  },
  validations: {
    form: {
      excel_file: { required },
    },
  },
  data() {
    return {
      isDisable: true,
      form: {
        excel_file: "",
        email: "",
        note: "",
      },
      filename: "",
      isLoading: false,
    };
  },
  created() {
    this.form.email = this.$cookies.get("back_office_admin_email");
  },
  methods: {
    onFileChange(file) {
      this.filename = file.name;
      this.isDisable = false;
      const str =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.form.excel_file = reader.result.substring(
          str.length,
          reader.result.length
        );
      };
    },
    deleteFile(value) {
      this.form.excel_file = null;
      this.filename = null;
      this.isDisable = true;
    },
    importData(id) {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.$refs.ModalInputEmail.show();
    },
    async sendForm() {
      this.$bus.$emit("showLoading");
      let { data } = await this.axios.post(
        `/import/importexcelcustomerpoint`,
        this.form
      );

      if (data.result === 1) {
        setTimeout(() => {
          this.$bus.$emit("hideLoading");
          this.isDisable = true;
          this.successAlert("Import Success!").then(() => {
            this.$router.push("/report/customer");
          });
        }, 3000);
      } else {
        this.$bus.$emit("hideLoading");
        this.errorAlert(data.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.color-btn {
  color: #fff;
}
</style>
